.el-container {
    flex-grow: 1;
    text-align: left;

    .el-date {
        font-size: 24px;
    }

    .el-cell {
        border: 1px solid gray;
    }


    .el-row {
        display: grid;

        &.el-stock-row {
            cursor: pointer;

        }

        &.selected {
            background-color: yellow;
            ;
        }
    }
}