.ticker-container {
    width: 40%;
    text-align: left;

    .ticker-header-container {
        margin-bottom: 45px;

        .ticker-symbol {
            font-size: 20px;
        }

        .ticker-name {

            font-size: 24px;
        }
    }
}