.sh-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 16px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 20px;

    .sh-title {
        flex-grow: 1;
        font-size: 18px;
        font-weight: 500px;
    }

}