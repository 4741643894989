.App {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;


  .cal-container {
    display: flex;
    
  }

  .friar-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }

}